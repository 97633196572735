import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
import { tippy } from 'vue-tippy'

import { createNotification } from '@/lib/unnotificationsqueue'

import sections from '../defaultNavigationDrawerSections'
import { RESPONSE_LEVEL } from '@/variables'
import CalendarView from '@/apps/core/components/CalendarView'
import Layout from '@/apps/core/components/Layout'

@Component({
  components: {
    CalendarView,
    Layout
  },
  props: {
    storeModule: {
      type: String,
      description: 'From Vue-Router: defines store module name to be used',
      required: true
    },
    layoutTitle: {
      type: String,
      description: 'From Vue-Router: Defines layout title',
      required: true
    },
    viewFilterName: {
      type: String,
      description: 'From Vue-Router: Defines CustomView\'s name to be fetched',
      required: true
    },
    redirectPromo: {
      type: String,
      description: 'From Vue-Router: Defines view\'s name to redirect to when creating a new promo',
      required: true
    },
    redirectCampaign: {
      type: String,
      description: 'From Vue-Router: Defines view\'s name to redirect to when creating a new campaign',
      required: true
    }
  },
  watch: {
    currentItem (newData) {
      if (!this.loading) {
        if (newData?.events?.length >= 500) this.$store.dispatch('addNotification', createNotification(this.$t('calendars.showOnlyFirst500Results'), RESPONSE_LEVEL.WARNING))
      }
    }
  }
})

class BaseCalendarView extends Vue {
  sections = sections
  DATE_FORMAT_UPDATE = 'YYYY-MM-DD'
  /**
   * Gets currentItem instance from defined storeModule
   * @returns {Object}
   */
  get currentItem () {
    return this.$store.state[this.storeModule].currentItem
  }

  /**
   * Gets loading state from defined storeModule
   * @returns {boolean}
   */
  get loading () {
    return this.$store.state[this.storeModule].loading
  }

  /**
   * Gets isCurrentItemFetched state from defined storeModule
   * @returns {boolean}
   */
  get isCurrentItemFetched () {
    return this.$store.state[this.storeModule].isCurrentItemFetched
  }

  /**
   * Gets full action name to dispatch when redirecting to create a new campaign (value depends on current view)
   * @returns {string}
   */
  get campaignDispatch () {
    return this.redirectCampaign === 'CampaignDetail' ? 'campaigns/createCampaign' : 'campaignTemplates/createCampaignTemplate'
  }

  get pathToRedirectWhenPromoSelected () {
    return this.$store.state[this.storeModule].currentItem.path_to_redirect_when_promo_selected
  }

  /**
   * Event handler for CalendarView's and CustomView's @input
   * @param items {Object} mutated instance from currentItem to save
   */
  updateItem (items) {
    if (this.isCurrentItemFetched && this.storeModule === 'clientsCalendar') {
      this.reloadResources(items)
    }
    this.$store.dispatch(`${this.storeModule}/updateCurrentItem`, { ...this.currentItem, ...items })
    if (this.isCurrentItemFetched) {
      this.$store.dispatch(`${this.storeModule}/filterCalendar`)
    }
  }

  /**
   * Event handler for @eventClick, redirects to respective Promo Detail
   * @param info
   */
  handleEventClick (info) {
    const id = this.pathToRedirectWhenPromoSelected === 'PromoTemplateDetail' ||
             this.pathToRedirectWhenPromoSelected === 'PromoDetail' ? info.event.id : info.event.extendedProps.setProp[1]
    let routeData = this.$router.resolve({
      name: this.pathToRedirectWhenPromoSelected,
      params: { id: id }
    })
    window.open(routeData.href, '_blank')
  }

  /**
   * Event handler for @eventClick
   * @param info
   */
  handleEditEvent (info) {
    this.$store.dispatch(`${this.storeModule}/editEvent`, info)
  }

  /**
   * Event handler for @eventMouseEnter (Hover on an event)
   * @param info
   */
  handleEventMouseEnter (info) {
    tippy(info.el, {
      theme: 'dark',
      content: this.$t('general.loading'),
      arrow: true,
      followCursor: true,
      onShow: instance => {
        setTimeout(() =>
          this.$store.dispatch(`${this.storeModule}/getContentPopUp`, info.event.id)
            .then(message => {
              instance.setContent(message)
            }), 100)
      }
    })
  }

  /**
   * Event handler for @select: creates new Campaign/CampaignTemplate for the date/client clicked
   * @param info
   */
  async handleSelectDate (info) {
    let date = this.getDateOfCalendar(info)
    let client = info.resource._resource.id.replace('__LY', '').replace('__CY', '')
    await this.onCreateObject(date, client)
  }

  /**
   * Creates Campaign/CampaignTemplate, either from scratch (@newItem event) or with date/client (handleSelectDate)
   * @param date
   * @param client
   */
  onCreateObject (date = null, client = null) {
    const toSendParams = {
      date: date,
      dhe: client
    }
    this.$store.dispatch(this.campaignDispatch, toSendParams)
      .then(response => {
        if (response.level === RESPONSE_LEVEL.SUCCESS) {
          let routerObject = { name: this.redirectCampaign, params: { id: response.id } }
          if (date && client) {
            routerObject.query = { date: date, id_client: client }
          }
          let routeData = this.$router.resolve(routerObject)
          window.open(routeData.href, '_blank')
        } else {
          this.$store.dispatch('addNotification', createNotification(response.message, response.level))
        }
      })
  }

  /**
   * Parses the date clicked for creating a new Campaign/CampaignTemplate
   * @param info
   * @returns {string}
   */
  getDateOfCalendar (info) {
    let isLastYear = info.resource.id.includes('_LY')
    let dateStr = info.dateStr

    if (isLastYear) {
      dateStr = moment(info.date).subtract(1, 'years').format(this.DATE_FORMAT_UPDATE)
    }
    return dateStr
  }

  /**
   * Client Calendars method to reload "resources"
   * @param items
   */
  reloadResources (items) {
    if (items.filters && this.currentItem.filters && items.filters.category !== this.currentItem.filters.category) {
      this.$store.dispatch(`${this.storeModule}/getResourcesClientsCalendar`, items.filters.category)
    }
  }
}

export default BaseCalendarView
