<template>
  <v-container class="pb-0" fluid>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-expansion-panels v-if="viewFilterName" v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="filter-title">
                {{ $t('general.filters') }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col class="d-flex justify-center">
                <v-form ref="form">
                  <CustomView
                    :view-name="viewFilterName"
                    :value="events.filters"
                    :options="options"
                    :viewLoading="loading"
                    :custom-component-catalog="customComponentCatalog"
                    @input="handleFilterEvents"
                  />
                </v-form>
              </v-col>
              <v-col class="d-flex flex-row-reverse pb-0" style="padding-top: 0;">
                <v-btn color="primary" outlined dark @click="update({ filters: filtersEvent })" :loading="loading">
                  <v-icon class="mr-3">fa-filter</v-icon>
                  {{ $t('delegations.applyFilters') }}
                </v-btn>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="fill-height pb-0 position-relative" ref="calendarSection">
        <div v-show="showCalendar" :class="loading ? 'no-content' : ''">
           <FullCalendar
          class="calendar"
          ref="fullCalendar"
          :options="calendarOptions"
        />
        </div>

          <v-progress-circular
            v-if="loading"
            class="custom-position"
            color="primary"
            indeterminate
            size="128"
            width="14"
          />
          <h2 v-else-if="!loading && !showCalendar" class="text-center mt-5">Select Filters...</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import FullCalendar from '@fullcalendar/vue'
import InteractionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import CustomView from '@/lib/uncustomui/components/CustomView'
import { BASE_URL, IS_CAC, IS_PNG } from '@/variables'
import { detectAndFormatOtherTypes } from '@/apps/core/helpers/utils'
import CalendarCampaignFilters from '@/apps/core/components/CalendarCampaignFilters'

export default {
  name: 'CalendarView',
  components: { FullCalendar, CustomView },
  props: {
    viewFilterName: String,
    events: {
      type: Object,
      required: true,
      default () {
        return { filters: {} }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    storeModule: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showCalendar: false,
      isCalendarEditable: true,
      isCalendarReady: false,
      isFirstLoad: true,
      applyBtnLoading: false,
      setDefaultFilters: true,
      filtersEvent: {},
      options: {
        context: {
          serverUrl: BASE_URL
        }
      },
      panel: 0,
      customComponentCatalog: { CalendarCampaignFilters }
    }
  },
  async mounted () {
    if (!isEmpty(this.$route.query)) {
      this.setDefaultFilters = false
      this.doQueryFilter(this.$route.query)
    }
    await this.$store.dispatch(`${this.storeModule}/getCalendar`, ({ setDefaultFilters: this.setDefaultFilters }))
    this.$store.commit(`${this.storeModule}/setCurrentItemFetched`)
  },
  computed: {
    /**
     * Used to set the name of the resource column of full calendar library
     */
    getResourceColumnName () {
      if (this.$props.viewFilterName === 'products_calendar' ||
        this.$props.viewFilterName === 'products_template_calendar') {
        return [{ headerContent: this.$t('general.products') }]
      } else {
        return [{ headerContent: this.$t('general.clients') }]
      }
    },
    addButtonText () {
      if (this.$props.viewFilterName === 'clients_template_calendar' ||
        this.$props.viewFilterName === 'products_template_calendar') {
        return this.$t('topics.newCampaignTemplate')
      } else {
        return this.$t('topics.newCampaignPromotion')
      }
    },
    calendarOptions () {
      return {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        locale: IS_PNG ? 'en' : 'es',
        plugins: [
          InteractionPlugin,
          resourceTimelinePlugin
        ],
        timeZone: 'local',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'btn_add_promo'
        },
        customButtons: {
          btn_add_promo: {
            text: IS_CAC ? this.addButtonText : this.$t('topics.newPromo'),
            click: () => this.$emit('newItem')
          },
          prev: {
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.prev()
              this.update(this.events)
            }
          },
          next: {
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi()
              calendarApi.next()
              this.update(this.events)
            }
          }
        },
        initialView: 'resourceTimelineYearByWeeks',
        defaultView: 'resourceTimelineYearByWeeks',
        scrollTime: '08:00',
        aspectRatio: 1.1,
        views: {
          resourceTimelineYearByWeeks: {
            type: 'resourceTimelineYear',
            buttonText: this.$t('calendars.forWeeks'),
            slotLabelInterval: { weeks: 1 }
          }
        },
        firstDay: 1,
        editable: true,
        selectable: true,
        nowIndicator: true,
        height: 'parent',
        filterResourcesWithEvents: this.filtersEvent ? !this.filtersEvent.show_empty_resources : undefined,
        eventResourceEditable: false,
        resourceAreaWidth: '200px',
        resourceOrder: 'group,title',
        resourceGroupField: this.events.resourceGroupField,
        resources: this.events.resourcesToShow,
        resourceAreaColumns: this.getResourceColumnName,
        events: this.events.events,
        eventMouseEnter: info => {
          if (window.eventScrolling) return
          this.$emit('eventMouseEnter', info)
        },
        eventClick: info => this.$emit('eventClick', info),
        eventDrop: info => this.$emit('eventDrop', info),
        eventResize: info => this.$emit('eventResize', info),
        dateClick: info => this.$emit('select', info),
        eventDragStart: x => {
          window.eventScrolling = true
        },
        eventDragStop: x => {
          window.eventScrolling = false
        },
        eventResizeStart: x => {
          window.eventScrolling = true
        },
        eventResizeStop: x => {
          window.eventScrolling = false
        }
      }
    }
  },
  methods: {
    /**
     * Event handler for CustomView's input event: parses API query params and emits event
     * @param: event (Object): The modified CustomView value prop
     */
    update (event, scroll = true) {
      if (this.$refs.form.validate()) {
        const url_query = this.getUrlQuery(event.filters)
        this.$emit('input', { ...event, url_query: url_query })
        this.showCalendar = true
        if (scroll) {
          const calendar = this.$refs.calendarSection
          const top = calendar.offsetTop
          window.scrollTo({
            top,
            behavior: 'smooth'
          })
        }
      }
    },
    /**
     * Parses filter param object into a string, which is going to be used as query params in the API call
     * @param: (Object): The filters object to be parsed into the URL
     */
    getUrlQuery (filter) {
      // deleting date range from previous filters, to avoid weird behavior
      delete filter['start']
      delete filter['end']

      let calendarApi = this.$refs.fullCalendar.getApi()
      let calendarYear = calendarApi.currentData ? parseInt(calendarApi.currentData.viewTitle) : new Date().getFullYear()

      const start = new Date(calendarYear, 0, 1)
      const end = new Date(calendarYear, 11, 31)

      const pad = (n) => {
        if (n < 10) {
          return `0${n}`
        }
        return n
      }

      let queriesArray = [
        `start=${start.getUTCFullYear()}-${pad(start.getUTCMonth() + 1)}-${pad(start.getUTCDate())}`,
        `end=${end.getUTCFullYear()}-${pad(end.getUTCMonth() + 1)}-${pad(end.getUTCDate())}`
      ]

      for (const p in filter) {
        if (filter.hasOwnProperty(p)) {
          if (typeof filter[p] === 'object') {
            if (Array.isArray(filter[p])) {
              for (const item in filter[p]) {
                queriesArray.push(encodeURIComponent(p) + '=' + encodeURIComponent(filter[p][item]))
              }
            } else {
              for (const item in filter[p]) {
                if (Array.isArray(filter[p][item])) {
                  for (const k in filter[p][item]) {
                    queriesArray.push(encodeURIComponent(item) + '=' + encodeURIComponent(filter[p][item][k]))
                  }
                } else {
                  queriesArray.push(encodeURIComponent(item) + '=' + encodeURIComponent(filter[p][item]))
                }
              }
            }
          } else {
            queriesArray.push(encodeURIComponent(p) + '=' + encodeURIComponent(filter[p]))
          }
        }
      }
      this.pushFilterQueryToUrl(queriesArray)
      return queriesArray.join('&')
    },
    /**
     * Transforms queries array into an Object, and pushes it into browser's URL query params
     * @param: (Array): List of all query params for filter API call.
     **/
    pushFilterQueryToUrl (queriesArray) {
      let queryObject = {}
      queriesArray.forEach((param) => {
        let [key, value] = param.split('=')
        queryObject[key] = queryObject[key] ? (
          Array.isArray(queryObject[key])
            ? [...queryObject[key], value]
            : [queryObject[key], value]
        ) : value
      })
      /** NOTE: If this methods makes parsing some data types too cumbersome:
       * push instead the path merged with the query (i.e. path: `${this.$route.path}?${fullQuery}`)
       */
      this.$router.push({ query: queryObject })
        .catch(() => {}) // Provided solution to catch 'NavigationDuplicated' errors https://github.com/vuejs/vue-router/issues/2872
    },
    /**
     * Called on component mount when browser URL contains query params, in order to parse such filters for the API call
     * - NOTE: For elements in multiSelect, an array is automatically built in order to allow multi-selection
     * @param: (Object) query params object (from browser URL) that defines desired filters
     */
    doQueryFilter (queryParams) {
      const multiSelect = ['status', 'products', 'product', 'product_children', 'client', 'distributor', 'topic', 'distributor_children', 'fortnight']
      let filters = {}
      for (const key in queryParams) {
        let value = queryParams[key]
        if (multiSelect.includes(key) && !Array.isArray(value)) {
          filters[key] = [detectAndFormatOtherTypes(value)]
        } else if (Array.isArray(value)) {
          filters[key] = value.map(el => detectAndFormatOtherTypes(el))
        } else {
          filters[key] = detectAndFormatOtherTypes(value)
        }
      }
      this.$store.commit(`${this.storeModule}/setCurrentItem`, {
        ...this.$store.state[this.storeModule].currentItem,
        filters
      })
    },
    handleFilterEvents: debounce(function (item) {
      if (this.isFirstLoad) {
        this.update({ filters: item })
        this.isFirstLoad = false
      }
      this.filtersEvent = item
      this.isCalendarReady = true
    }, 250)
  },
  watch: {
    'events.filters.product' (product) {
      if (product) {
        if (product.includes(-1) && product[0] !== -1 && product.length !== 1) {
          this.events.filters.product = [-1]
          this.update(this.events)
        } else if (product[0] === -1 && product.length !== 1) {
          this.events.filters.product.splice(0, 1)
          this.update(this.events)
        }
      }
    },
    'events.filters.product_category' (productsCategory) {
      if (productsCategory) {
        if (productsCategory.includes(-1) && productsCategory[0] !== -1 && productsCategory.length !== 1) {
          this.events.filters.product_category = [-1]
          this.update(this.events)
        } else if (productsCategory[0] === -1 && productsCategory.length !== 1) {
          this.events.filters.product_category.splice(0, 1)
          this.update(this.events)
        }
      }
    },
    'events.filters.client' (clients) {
      if (clients) {
        if (clients.includes(-1) && clients[0] !== -1 && clients.length !== 1) {
          this.events.filters.client = [-1]
          this.update(this.events)
        } else if (clients[0] === -1 && clients.length !== 1) {
          this.events.filters.client.splice(0, 1)
          this.update(this.events)
        }
      }
    },
    'events.filters.distributor' (distributors) {
      if (distributors) {
        if (distributors.includes(-1) && distributors[0] !== -1 && distributors.length !== 1) {
          this.events.filters.client = [-1]
          this.update(this.events)
        } else if (distributors[0] === -1 && distributors.length !== 1) {
          this.events.filters.client.splice(0, 1)
          this.update(this.events)
        }
      }
    }
  }
}

</script>

<style lang="scss">
  .no-content {
    filter: blur(8px) grayscale(100%);
  }

  .calendar {
    position: relative;
    height: 93vh !important;
  }

  .fc .fc-resource-timeline .fc-resource-group:not([rowspan]) {
    background: var(--v-accent-base);
    color: white;
    font-weight: 700;
  }

  .fc .fc-button-primary {
    background: var(--v-primary-base);
    border-color: var(--v-primary-base);
  }

  .fc .fc-button-primary:hover {
    background: var(--v-anchor-base);
    border-color: var(--v-anchor-base);
  }

  .container .row .col .v-card {
    box-shadow: none !important;
  }

  .container .row .col .v-expansion-panels .v-expansion-panel .v-expansion-panel-header .filter-title {
    font-weight: 500;
    letter-spacing: .0125em;
    font-size: 1.25rem;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    line-height: 1rem;
    padding-top:.5rem;
    margin-bottom: 0;
  }

  .container .row .col .v-expansion-panels .v-expansion-panel .v-expansion-panel-header {
    padding-top: 0;
    padding-bottom: 0;
  }

  .v-form .container {
    padding: 0;
  }

  .custom-position {
    position: absolute;
    left: 45%;
    top: 55%;
    z-index: 99;
  }

  #inspire > div > main > div > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div.d-flex.justify-center.col > form > div > div > div,
  #inspire > div > main > div > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div.d-flex.justify-center.col > form > div > div > div > div > div,
  #inspire > div > main > div > div > div > div > div > div > div:nth-child(1) > div > div > div > div > div > div.d-flex.justify-center.col {
    padding-top: 0;
    padding-bottom: 0;
  }

  #inspire > div > main > div > div > div > div {
    padding-bottom: 0 !important;
  }
</style>
