<template>
  <v-row>
    <v-col cols="3" class="py-0" v-for="field in fields" :key="field.name">
      <v-autocomplete
        v-if="field.type === 'autocomplete'"
        v-model="field.value"
        @input="manageValues($event, field)"
        :items="field.items"
        :rules="field.rules"
        :label="field.label"
        :loading="field.loading"
        :item-value="field.itemValue"
        :item-text="field.itemText"
        :multiple="field.multiple"
        :clearable="field.multiple"
        :disabled="manageDisabled(field)"
      >
        <template v-if="chipMode" v-slot:selection="{ item, index }">
          <v-chip small v-if="index < 4">
            <span v-text="item.name || item.value" />
          </v-chip>
          <span
            v-if="index === 4"
            class="grey--text text-caption"
            v-text="`(+${field.value.length - 4} others)`"
          />
        </template>
      </v-autocomplete>

      <v-checkbox
        v-if="field.type === 'checkbox'"
        v-model="field.value"
        :label="field.label"
        :disabled="manageDisabled(field)"
      />
    </v-col>
  </v-row>
</template>

<script>
import apiClient from '@/lib/unlogin/store/apiclient'

// TODO: This component should be configurable from the backend.
export default {
  name: 'CalendarCampaignFilters',
  props: {
    viewLoading: Boolean,
    colorCriterionUrl: String,
    chipMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      'fields': {
        'distributor': {
          'name': 'distributor',
          'label': 'Customers',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': true,
          'itemValue': 'id',
          'itemText': 'name',
          'defaultValue': [-1],
          'url': '/distributor-hierarchy-levels/planning__customer/hierarchy-elements/?add-select-all-choice=True',
          'required': true,
          'disabled': false,
          'value': []
        },
        'product': {
          'name': 'product',
          'label': 'Subsectors',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': true,
          'itemValue': 'id',
          'itemText': 'name',
          'defaultValue': [-1],
          'url': '/product-hierarchy-levels/localplanning__subsector/hierarchy-elements/?add-select-all-choice=True',
          'required': true,
          'disabled': false,
          'value': []
        },
        'status': {
          'name': 'status',
          'label': 'Status',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': true,
          'itemValue': 'key',
          'itemText': 'value',
          'defaultValue': ['allValues'],
          'url': '/campaign-status-choices/',
          'required': true,
          'disabled': false,
          'value': []
        },
        'color_criterion': {
          'name': 'color_criterion',
          'label': 'Colored By',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': false,
          'itemValue': 'key',
          'itemText': 'value',
          'defaultValue': 'campaign__status_id',
          'url': '/campaign-clients-calendar-color-choices/',
          'required': true,
          'disabled': false,
          'value': null
        },
        'distributor_children': {
          'name': 'distributor_children',
          'label': 'Banner',
          'loading': false,
          'type': 'autocomplete',
          'dependencies': ['distributor'],
          'items': [],
          'multiple': true,
          'itemValue': 'id',
          'itemText': 'name',
          'url': '/distributor-hierarchy-levels/planning__banner/hierarchy-elements/',
          'required': true,
          'disabled': false,
          'value': []
        },
        'product_children': {
          'name': 'product_children',
          'label': 'Category',
          'loading': false,
          'type': 'autocomplete',
          'dependencies': ['product'],
          'items': [],
          'multiple': true,
          'itemValue': 'id',
          'itemText': 'name',
          'url': '/product-hierarchy-levels/localplanning__category/hierarchy-elements/',
          'required': true,
          'disabled': false,
          'value': []
        },
        'fortnight': {
          'name': 'fortnight',
          'label': 'Fortnight',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': true,
          'itemValue': 'key',
          'itemText': 'value',
          'defaultValue': ['F1', 'F2'],
          'url': '/custom_fields/choices/68/',
          'required': true,
          'disabled': false,
          'value': []
        },
        'topic': {
          'name': 'topic',
          'label': 'KBD Type',
          'loading': false,
          'type': 'autocomplete',
          'items': [],
          'multiple': true,
          'itemValue': 'id',
          'itemText': 'name',
          'defaultValue': [-1],
          'url': '/budget-concept-hierarchy-levels/promotions__kbd_type/hierarchy-elements/?add-select-all-choice=True',
          'required': true,
          'disabled': false,
          'value': []
        },
        'previous_year': {
          'name': 'previous_year',
          'label': 'Show last year promotions',
          'type': 'checkbox',
          'value': false,
          'disabled': false
        },
        'show_empty_resources': {
          'name': 'show_empty_resources',
          'label': 'Show rows without promotions',
          'type': 'checkbox',
          'value': false,
          'disabled': false
        }
      }
    }
  },
  computed: {
    filtersValue () {
      const values = {}
      Object.values(this.fields).forEach(field => {
        const fieldName = field.name
        values[fieldName] = field.value
      })
      return values
    },
    isParamURL () {
      const params = this.$route.query
      return Object.keys(params).length > 0
    },
    dependenciesRelations () {
      const relations = {}
      Object.values(this.fields).forEach(field => {
        if (field.dependencies && field.dependencies.length) {
          field.dependencies.forEach(dependency => { relations[dependency] = field.name })
        }
      })
      return relations
    }
  },
  created () {
    Object.values(this.fields).forEach(field => {
      if (field['name'] === 'color_criterion') {
        field['url'] = this.colorCriterionUrl || field['url']
      }

      this.getFieldList(field)
    })
  },
  watch: {
    filtersValue (newValue, oldValue) {
      this.autoDependencies(newValue, oldValue)
      this.$emit('input', { filters: newValue, customFilterCalendar: true })
    }
  },
  methods: {
    async getFieldList (field) {
      try {
        field.loading = true
        if (field.required) {
          if (field.multiple) {
            field.rules = [v => v.length > 0 || 'Required']
          } else {
            field.rules = [v => !!v || 'Required']
          }
        }

        if (field.url && !field.dependencies) {
          const { data } = await apiClient.get(field.url)
          field.items = Array.isArray(data) ? data : data.results

          if (this.isParamURL && this.$route.query[field.name]) {
            const params = this.$route.query
            Object.entries(params).forEach(param => {
              const paramName = param[0]
              if (this.fields[paramName]) {
                let paramValue = param[1]
                if (Array.isArray(paramValue)) paramValue = paramValue.map(item => isNaN(item) ? item : +item)
                if (!isNaN(+paramValue)) paramValue = [+paramValue]
                if (paramValue === 'true') paramValue = true
                if (paramValue === 'false') paramValue = false
                this.fields[paramName].value = paramValue
              }
            })
          } else if (field.defaultValue) {
            if (field.defaultValue[0] === 'allValues') {
              const values = []
              field.items.forEach(item => {
                values.push(item.key)
              })
              this.fields[field.name].value = values
            } else {
              this.fields[field.name].value = field.defaultValue
            }
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        field.loading = false
      }
    },
    manageValues (values, fieldParams) {
      if (Array.isArray(values)) {
        const fieldName = fieldParams.name
        const key = fieldParams.itemValue
        const typeOfValue = typeof fieldParams.items[0][key]
        if (typeOfValue === 'number') {
          const lastSelection = values[values.length - 1]
          const fieldSelected = this.fields[fieldName]
          if (lastSelection === -1) {
            fieldSelected.value = [-1]
          } else {
            fieldSelected.value = values.filter(value => value >= 0)
          }
        }
      }
    },
    manageDependenciesOf (fieldName) {
      const fieldSelected = this.fields[fieldName]
      if (fieldSelected.dependencies && fieldSelected.dependencies.length) {
        fieldSelected.dependencies.forEach(dependency => {
          fieldSelected.disabled = !(this.filtersValue[dependency] && this.filtersValue[dependency].length > 0)
          if (fieldSelected.disabled) {
            fieldSelected.items = []
            fieldSelected.value = []
          } else {
            fieldSelected.items = []
            fieldSelected.value = []
            this.filtersValue[dependency].forEach(async value => {
              try {
                fieldSelected.loading = true
                const addedValue = value === -1 ? '?depth=1&add-select-all-choice=True' : value + '/?depth=1'
                const urlFixed = fieldSelected.url + addedValue
                const { data } = await apiClient.get(urlFixed)
                if (value === -1) {
                  fieldSelected.items.push(...data)
                } else {
                  fieldSelected.items.push(...data.children)
                  if (fieldSelected.value.indexOf(value) === -1) {
                    fieldSelected.value.push(value)
                  }
                  data.children.forEach(
                    value => {
                      if (fieldSelected.value.indexOf(value.id) === -1) {
                        fieldSelected.value.push(value.id)
                      }
                    }
                  )
                }
              } catch (e) {
                console.error(e)
              } finally {
                fieldSelected.loading = false
              }
            })
          }
        })
      }
    },
    autoDependencies (newValues, oldValues) {
      Object.entries(this.dependenciesRelations).forEach(dependencyRelation => {
        const parent = dependencyRelation[0]
        const children = dependencyRelation[1]
        if (newValues[parent] !== oldValues[parent]) this.manageDependenciesOf(children)
      })
    },
    manageDisabled ({ loading, disabled }) {
      return disabled || loading || this.viewLoading
    }
  }
}
</script>

<style scoped>

</style>
